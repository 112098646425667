<template>

    <div class="w-full h-full px-3 py-4">
        <div class="h-auto w-full rounded-lg shadow_box flex flex-col p-2 mb-6" :class="'bg-module-'+mode">
            <div class="h-12 flex flex-row px-2">
                <div class="h-full w-10/12 flex flex-row">
                    <div class="h-full w-full flex flex-col justify-center items-start">
                        <span class="text-2xl font-semibold" :class="'text-dfont-'+mode">{{$t('filters')}}</span>
                    </div>
                </div>
                <div class="h-full w-2/12 flex flex-col justify-start items-end"></div>
            </div>
            <All model="Groupdimensions"
                :immediate="true"
                :data.sync="dimensions"
                v-slot="{ data, loading}"
                >
                <div v-if="!loading" class="h-auto px-2 mt-6 flex flex-col justify-center items-start">
                    <span class="text-sm text-gray">{{ $t('indicator') }}</span>
                    <div class="h-8 w-full">
                        <div class="h-full w-full rounded-lg flex flex-row justify-between items-center px-2" :class="'bg-box-'+mode">
                            <!-- <el-select v-model="dimensionOption" :placeholder="$t('select')" class="w-full">
                                <el-option
                                v-for="item in data"
                                :key="item.Key"
                                :label="translate(item.Name)"
                                :value="item.Key"
                                class="w-full">
                                </el-option>
                            </el-select> -->
                            <select name="dimension" v-model="dimensionOption" class="h-full w-full" :class="'bg-box-'+mode+' text-dfont-'+mode">
                                <option v-for="(el,index) in data" :key="index" :value="el.Key" :class="'text-dfont-'+mode">{{translate(el.Name)}}</option>
                            </select>
                        </div>
                    </div>
                </div>
            </All>
            <!-- <div class="h-auto px-2 mt-6 flex flex-col justify-center items-start">
                <span class="text-sm text-gray">{{$t('period')}}</span>
                <div class="h-8 w-full">
                    <div class="h-full bg-lightgray rounded-lg flex flex-row justify-between items-center px-2">
                        <el-select v-model="period" :placeholder="$t('select')" class="w-full">
                            <el-option
                            v-for="item in periods"
                            :key="item.value"
                            :label="item.name"
                            :value="item.value">
                            </el-option>
                        </el-select>
                    </div>
                </div>
            </div> -->

            <div class="h-10 mt-6 px-12">
                <div class="h-full rounded-lg bg-red flex flex-row justify-center items-center cursor-pointer" @click="applyfilters()">
                    <span class="text-lg text-white">{{$t('apply')}}</span>
                </div>
            </div>
            <div class="h-6"></div>
        </div>
    </div>

</template>

<script>
import { All } from '@/api/components';
import { state, actions } from '@/store';

export default {
    components:{
        All
    },
    data(){
        return{
            periods: [
                { 
                    name: this.$t("monthly"),
                    value: 'month'
                },
                { 
                    name: this.$t("quarterly"),
                    value: 'quarter'
                },
                { 
                    name: this.$t("annual"),
                    value: 'year'
                }
            ],
            period: '',
            dimensionOption:null,
            dimensions: []
        }
    },
    methods:{
        translate(word) {
            switch (word) {
                case 'Facturación':
                    return this.$t("billing")
                    break;
                case 'Metros cuadrados':
                    return this.$t("squareMeters")
                    break;
                case 'Precio medio':
                    return this.$t("averagePrice")
                    break;
                case 'Margen':
                    return this.$t("margin")
                    break;
            }
        },
        applyfilters(){
            actions.setPeriod(this.period);
            actions.setDimensionGroup(this.dimensionOption);
            actions.setDimensionName(this.dimensions[this.dimensionOption].Name)
            this.$router.go(-1);
        }
    },
    computed:{
        mode(){
            return state.mode
        }
    },
    mounted(){
        this.dimensionOption = state.dimensionGroup
        this.period = state.period
    }
}
</script>

<style>

    /* .el-input__inner{
        background-color: rgba(0,0,0,0) !important;
        border: 0;
        color: #2C5358;
    } */

</style>